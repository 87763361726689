export * from "../../ce/utils/serviceWorkerUtils";
import { APP_MODE } from "entities/App";
import {
  getConsolidatedApiPrefetchRequest,
  type TApplicationParams,
} from "../../ce/utils/serviceWorkerUtils";

export const cachedApiUrlRegex = new RegExp(
  "/api/v1/(consolidated-api|moduleInstances)",
);

/**
 * Function to get the prefetch request for module instance apis
 */
export const getPrefetchModuleApiRequests = (
  applicationProps: TApplicationParams,
): Request[] => {
  const prefetchRequests: Request[] = [];
  const { appMode, basePageId, branchName, origin } = applicationProps;

  if (!basePageId) {
    return prefetchRequests;
  }

  const searchParams = new URLSearchParams();

  searchParams.append("contextId", basePageId);
  searchParams.append("contextType", "PAGE");
  searchParams.append("viewMode", (appMode === APP_MODE.PUBLISHED).toString());

  const headers = new Headers();

  if (branchName) {
    headers.append("Branchname", branchName);
  }

  // Add get module instances api request
  const moduleInstanceUrl = `${origin}/api/v1/moduleInstances?${searchParams.toString()}`;
  const moduleInstanceRequest = new Request(moduleInstanceUrl, {
    method: "GET",
    headers,
  });

  prefetchRequests.push(moduleInstanceRequest);

  // Add module entities api request
  const moduleEntitiesUrl = `${origin}/api/v1/moduleInstances/entities?${searchParams.toString()}`;
  const moduleEntitiesRequest = new Request(moduleEntitiesUrl, {
    method: "GET",
    headers,
  });

  prefetchRequests.push(moduleEntitiesRequest);

  return prefetchRequests;
};

/**
 * Function to get the prefetch requests for an application
 */
export const getPrefetchRequests = (
  applicationParams: TApplicationParams,
): Request[] => {
  const prefetchRequests: Request[] = [];
  const consolidatedApiPrefetchRequest =
    getConsolidatedApiPrefetchRequest(applicationParams);

  if (consolidatedApiPrefetchRequest) {
    prefetchRequests.push(consolidatedApiPrefetchRequest);
  }

  const moduleApiPrefetchRequests =
    getPrefetchModuleApiRequests(applicationParams);

  prefetchRequests.push(...moduleApiPrefetchRequests);

  return prefetchRequests;
};
